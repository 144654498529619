import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { BasicPage } from '../components/BasicPage';
import { ApiWebUrl} from '../utils';
import './ReporteExtraCurriculares.css';
import TableToExcel from "react-table-to-excel";



function ReporteConstancias(props) {

    const [data, setData] = useState([]);
    const [usuario] = useState("");
    const [dataFilter, setDataFilter] = useState([]);
    const [campoBuscar, setCampoBuscar] = useState("");

    var rutaServicio = ApiWebUrl + "reportes/instituciones.php";


    useEffect(() => {
        readData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readData = async (e) => {

        const result = await axios(rutaServicio);
        setData(result.data);
        setDataFilter(result.data);
    }


    const showTable = () => {
        return (
            <table className='table table-bordered table-striped table-hover' id="tabla-impresion">
                <thead className="table-dark">
                    <tr>
                        
                        <th>Institución</th>
                        <th>Estudiante</th>
                        <th>Tipo Actividad</th>
                        <th>Actividad</th>
                        
                        <th>Fecha</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {dataFilter.map(item =>
                        <tr key={item.idAlumno}>

                            <>
                                <td>{item.institucion}</td>
                                <td>{item.alumno}</td>
                                <td>{item.tipoActividad}</td>
                                <td>{item.actividad}</td>
                                <td>{item.fecha}</td>
                                <td className="no-print"><a href={item.ruta} target="_blank" rel="noreferrer" title="Ver constancia"><i className="bi bi-file-earmark-text"></i></a></td>
                            </>

                        </tr>
                    )}
                </tbody>
            </table>
        )
    }



    const buscar = () => {
        let datosFiltrados = data.filter((item) => {
            return campoBuscar !== "" ? 
            (item["institucion"]).toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
            (item["tipoActividad"]).toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
            (item["actividad"] + "").toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
            (item["alumno"] + "").toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
            (item["fecha"] + "").toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1
            : true
        })
        setDataFilter(datosFiltrados);
    }

    function PrintElem(elem) {
        var mywindow = window.open('', 'PRINT', 'height=500,width=800');

        mywindow.document.write('<html><head><title>' + document.title + '</title>');
        mywindow.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">'
            + '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>'
            + '<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap" rel="stylesheet">');

        mywindow.document.write('<style>body{font-family:Poppins;padding:100px 20px;}h3{text-align:center;}</style>');
        mywindow.document.write('<style>@media print {.no-print{display:none;}}</style>');
        mywindow.document.write('</head><body>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }

    const exportarExcel = () => {
        let table = document.querySelector('#tabla-impresion');
        TableToExcel.convert(table);
    }
    return (
        <>
            <div id="area-impresion">
                <BasicPage title="Búsqueda de Constancias" icon={<i className="bi bi-people-fill"></i>} />

                <div className="container">
                    <div className='no-print'>
                        <div className="mb-3">
                            <input type="text" className='form-control' placeholder={'Ingrese lo que va a buscar'}
                                value={campoBuscar} onChange={(event) => setCampoBuscar(event.target.value)} />
                        </div>
                        <div className="mb-3">
                            <button className='btn btn-primary' id='btnBuscar' onClick={() => buscar()}>Buscar</button>
                        </div>
                    </div>
                    {showTable()}
                </div>



            </div>
            <div className="container">
                <button className="btn btn-primary" onClick={() => PrintElem("area-impresion")}>Imprimir</button>
                <button className="btn btn-success" onClick={() => exportarExcel()}>Exportar a Excel</button>
            </div>

            {usuario !== null
                ? ""
                : ""}


        </>
    );
}


export default ReporteConstancias;