import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBar } from "../common/AppBar";

export const ProtectedLayout = () => {
    const { user } = useAuth();
    const outlet = useOutlet();

    if (!user) {
        return <Navigate to="/" />;
    }

    return (
        <div>
            <AppBar
                pagesLeft={[
                   // { label: "Estudiantes", path: "estudiantes" }
                ]}
                pagesRight={[
                    
                ]}
                pagesUser={[
                   // { label: "Configuración", path: "settings" },
                   // { label: "Perfil", path: "profile" }
                ]}
            />
            {outlet}
        </div>
    );
};