import React from 'react';
import { BasicPage } from '../components/BasicPage';

function Escritorio(props) {
    return (
        <>
            <BasicPage title="Escritorio" icon={<i className="bi bi-window-dock"></i>} />

            
        </>
    );
}

export default Escritorio;