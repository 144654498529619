import axios from "axios";
import { useEffect, useState } from "react";
import { BasicPage } from "../components/BasicPage";
import { useAuth } from "../hooks/useAuth";
import { ApiWebUrl, callWebService } from "../utils";

export const ProfileStudentPage = () => {
    const { user } = useAuth();
    const rutaServicio = ApiWebUrl + "admin/alumnos.php?idAlumno=" + user.idUsuarioTotal;
    const [nombres, setNombres] = useState("");
    const [apellidos, setApellidos] = useState("");
    const [correo, setCorreo] = useState("");
    const [clave, setClave] = useState("");
    const [codigoUNI, setCodigoUNI] = useState("");
    const [especialidad, setEspecialidad] = useState("");
    const [telefono, setTelefono] = useState("");
    const [fechaRegistro, setFechaRegistro] = useState("");
    const [cantidad, setCantidad] = useState("");
    const [horas, setHoras] = useState("");
    
    const [nota, setNota] = useState("");
    const [usuario, setUsuario] = useState("");
    const [comentario, setComentario] = useState("");
    const [fechaAcreditacion, setFechaAcreditacion] = useState("");
    const [idPeriodoIngreso, setIdPeriodoIngreso] = useState(0);
    const [idEspecialidad, setIdEspecialidad] = useState(0);

    useEffect(() => {
        readData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readData = async (e) => {
        const result = await axios(rutaServicio);
        setNombres(result.data[0].nombres);
        setApellidos(result.data[0].apellidos);
        setCodigoUNI(result.data[0].codigoUNI);
        setEspecialidad(result.data[0].especialidad);
        setCorreo(result.data[0].correo);
        setTelefono(result.data[0].telefono);
        setClave(result.data[0].clave);
        setFechaRegistro(result.data[0].fechaRegistro);
        setCantidad(result.data[0].cantidad);
        setHoras(result.data[0].horas);

        setNota(result.data[0].nota);
        setUsuario(result.data[0].usuario);
        setComentario(result.data[0].comentario);
        setFechaAcreditacion(result.data[0].fechaAcreditacion);
        setIdPeriodoIngreso(result.data[0].idPeriodoIngreso);
        setIdEspecialidad(result.data[0].idEspecialidad);
    }


    const callService = async (event) => {
        event.preventDefault();
        let respuesta = window.confirm("¿Esta seguro que desea actualizar los datos?");
        if (respuesta === true) {
            const dataService = {
                idAlumno: user.idUsuarioTotal,
                nombres: nombres,
                apellidos: apellidos,
                codigoUNI: codigoUNI,
                correo: correo,
                telefono: telefono,
                clave: clave,
                idPeriodoIngreso: idPeriodoIngreso,
                idEspecialidad: idEspecialidad
            };
            await callWebService(rutaServicio, dataService, 2)
        }
        readData();

    }
    return (
        <>
            <BasicPage title="Perfil" icon={<i className="bi bi-person-fill"></i>} />
            <section id="registro" className="padded">
                <div className="container">
                    <form onSubmit={(event) => callService(event)}>
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Nombres</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={nombres} required readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Apellidos</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={apellidos} required readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">CódigoUNI</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={codigoUNI} required readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Especialidad</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={especialidad} required readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Correo electrónico</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="email" className="form-control" value={correo} required readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Teléfono</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={telefono} required
                                            onChange={(e) => setTelefono(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Fecha de registro</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={fechaRegistro} required readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Constancias registradas</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={cantidad} required readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Total de horas registradas</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={horas} required readOnly
                                        />
                                    </div>
                                </div>
                                

                                {usuario !== null && (<>
                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label className="form-label">Nota</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" value={nota} required readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label className="form-label">Usuario acreditador</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" value={usuario} required readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label className="form-label">Comentario</label>
                                        </div>
                                        <div className="col-md-8">
                                            <textarea rows="7" className="form-control" value={comentario} required readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-4">
                                            <label className="form-label">Fecha de acreditación</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" value={fechaAcreditacion} required readOnly
                                            />
                                        </div>
                                    </div>
                                </>)
                                }

                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Contraseña</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input id="txtPassword" type="password" className="form-control" value={clave} required
                                            onChange={(e) => setClave(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-8">
                                        <div className='mb-2'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                    onClick={(event) => document.getElementById("txtPassword").setAttribute("type", event.target.checked ? "text" : "password")} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    Mostrar contraseña
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-primary">Actualizar perfil</button>
                                    </div>
                                }
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};
