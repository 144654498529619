import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BasicPage } from '../components/BasicPage';
import StartCrud from '../components/StartCrud';
import { ApiWebUrl, botonesTabla, callWebService, showModal } from '../utils';

function Alumnos(props) {
    const titulo = "estudiante";
    const rutaServicio = ApiWebUrl + "admin/alumnos.php";
    const [tipoModal, setTipoModal] = useState(0);
    const [data, setData] = useState([]);
    const [subData1, setSubData1] = useState([]);
    const [subData2, setSubData2] = useState([]);
    const [id, setId] = useState("");

    const [nombres, setNombres] = useState("");
    const [apellidos, setApellidos] = useState("");
    const [codigoUNI, setCodigoUNI] = useState("");
    const [correo, setCorreo] = useState("");
    const [telefono, setTelefono] = useState("");
    const [clave, setClave] = useState("");
    const [idPeriodoIngreso, setIdPeriodoIngreso] = useState(0);
    const [idEspecialidad, setIdEspecialidad] = useState(0);


    useEffect(() => {
        readData();
        readSubData1();
        readSubData2();
        clearFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readData = async (e) => {
        const result = await axios(rutaServicio);
        setData(result.data);
    }
    const readSubData1 = async (e) => {
        const rutaServicio = ApiWebUrl + "admin/periodosingreso.php";
        const result = await axios(rutaServicio);
        setSubData1(result.data);
    }
    const readSubData2 = async (e) => {
        const rutaServicio = ApiWebUrl + "admin/especialidades.php";
        const result = await axios(rutaServicio);
        setSubData2(result.data.slice(1));
    }


    const fillData = (item) => {
        setId(item.idAlumno);
        setNombres(item.nombres);
        setApellidos(item.apellidos);
        setCodigoUNI(item.codigoUNI);
        setCorreo(item.correo);
        setTelefono(item.telefono);
        setClave(item.clave);
        setIdPeriodoIngreso(item.idPeriodoIngreso);
        setIdEspecialidad(item.idEspecialidad);
    }
    const clearFields = () => {
        setId("");
        setNombres("");
        setApellidos("");
        setCodigoUNI("");
        setCorreo("");
        setTelefono("");
        setClave("");
        setIdPeriodoIngreso(0);
        setIdEspecialidad(0);
    }

    const showTable = () => {
        return (
            <table className='table table-bordered table-striped table-hover crud'>
                <thead className="table-dark">
                    <tr>
                        <th>Código</th>
                        <th>Nombres</th>
                        <th>Apellidos</th>
                        <th>Código UNI</th>
                        <th>Correo</th>
                        <th>Teléfono</th>
                        <th>Período</th>
                        <th>Especialidad</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item =>
                        <tr key={item.idAlumno}>
                            <td>{item.idAlumno}</td>
                            <td>{item.nombres}</td>
                            <td>{item.apellidos}</td>
                            <td>{item.codigoUNI}</td>
                            <td>{item.correo}</td>
                            <td>{item.telefono}</td>
                            <td>{item.periodoingreso}</td>
                            <td>{item.especialidad}</td>
                            {botonesTabla(item, fillData, setTipoModal)}
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }


    const formContent = () => {
        return (
            <div className="modal-body">
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Código</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={id} readOnly />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Nombres</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={nombres} required
                            onChange={(event) => setNombres(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Apellidos</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={apellidos} required
                            onChange={(event) => setApellidos(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Código UNI</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={codigoUNI} required
                            onChange={(event) => setCodigoUNI(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Correo electrónico</label>
                    </div>
                    <div className="col-md-8">
                        <input type="email" className="form-control" value={correo} required
                            onChange={(event) => setCorreo(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Teléfono</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={telefono} required
                            onChange={(event) => setTelefono(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Período de ingreso</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idPeriodoIngreso} required
                            onChange={(event) => setIdPeriodoIngreso(event.target.value)}>
                            <option value="">Seleccione el período de Ingreso</option>
                            {subData1.map(item =>
                                <option value={item.idPeriodoIngreso} key={item.idPeriodoIngreso}>{item.nombre}</option>
                            )}
                        </select>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Especialidad</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idEspecialidad} required
                            onChange={(event) => setIdEspecialidad(event.target.value)}>
                            <option value="">Seleccione una Especialidad</option>
                            {subData2.map(item =>
                                <option value={item.idEspecialidad} key={item.idEspecialidad}>{item.nombre}</option>
                            )}
                        </select>
                    </div>
                </div>

            </div>
        )
    }

    const callService = async (event) => {
        event.preventDefault();
        const dataService = {
            idAlumno: id,
            nombres: nombres,
            apellidos: apellidos,
            codigoUNI: codigoUNI,
            correo: correo,
            telefono: telefono,
            clave: clave,
            idPeriodoIngreso: idPeriodoIngreso,
            idEspecialidad: idEspecialidad
        };
        await callWebService(rutaServicio, dataService, tipoModal)
        readData();
        clearFields()
    }

    const startInsert = () => {
        clearFields(); setTipoModal(1)
    }

    return (
        <>
            <BasicPage title="Estudiantes" icon={<i className="bi bi-people-fill"></i>} />
            <StartCrud titulo={titulo} table={showTable()} startInsert={startInsert} />
            {showModal(tipoModal, formContent, titulo, nombres, callService)}
        </>
    );
}


export default Alumnos;