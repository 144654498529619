import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BasicPage } from '../components/BasicPage';
import StartCrud from '../components/StartCrud';
import { ApiWebUrl, botonesTabla, callWebService, showModal } from '../utils';

function Instituciones(props) {
    const titulo = "institución";
    const rutaServicio = ApiWebUrl + "admin/instituciones.php";
    const [tipoModal, setTipoModal] = useState(0);
    const [data, setData] = useState([]);
    const [subData1, setSubData1] = useState([]);
    const [id, setId] = useState("");
    const [nombre, setNombre] = useState("");
    const [ruc, setRuc] = useState("");
    const [telefono, setTelefono] = useState("");
    const [correo, setCorreo] = useState("");
    const [idTipoInstitucion, setIdTipoInstitucion] = useState("");

    useEffect(() => {
        readData();
        readSubData1();
        clearFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readData = async(e) => {
        const result = await axios(rutaServicio);
        setData(result.data);
    }

    const readSubData1 = async(e) => {
        const rutaServicio = ApiWebUrl + "admin/tiposinstitucion.php";
        const result = await axios(rutaServicio);
        setSubData1(result.data);
    }

    const fillData = (item) => {
        setId(item.idInstitucion);
        setNombre(item.nombre);
        setRuc(item.ruc);
        setTelefono(item.telefono);
        setCorreo(item.correo);
        setIdTipoInstitucion(item.idTipoInstitucion);
    }

    const clearFields = () => {
        setId("");
        setNombre("");
        setRuc("");
        setTelefono("");
        setCorreo("");
        setIdTipoInstitucion("");
    }

    const showTable = () => {
        return (
            <table className='table table-bordered table-striped table-hover crud'>
                <thead className="table-dark">
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>RUC</th>
                        <th>Teléfono</th>
                        <th>Correo electrónico</th>
                        <th>Tipo</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item =>
                        <tr key={item.idInstitucion}>
                            <td>{item.idInstitucion}</td>
                            <td>{item.nombre}</td>
                            <td>{item.ruc}</td>
                            <td>{item.telefono}</td>
                            <td>{item.correo}</td>
                            <td>{item.tipo}</td>
                            {botonesTabla(item, fillData, setTipoModal)}
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    const formContent = () => {
        return (
            <div className="modal-body">
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Código</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={id}
                            readOnly />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Nombre</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={nombre} required
                            onChange={(event) => setNombre(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">RUC</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={ruc} required
                            minLength="11" maxLength="11"
                            onChange={(event) => setRuc(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Teléfono</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={telefono} required
                            minLength="9" maxLength="9"
                            onChange={(event) => setTelefono(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Correo electrónico</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={correo} required
                            onChange={(event) => setCorreo(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Tipo de institución</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example" 
                            value={idTipoInstitucion} required
                            onChange={(event) => setIdTipoInstitucion(event.target.value)}>
                            <option value="">Seleccione un tipo de institución</option>
                            {subData1.map(item =>
                                <option value={item.idTipoInstitucion} key={item.idTipoInstitucion}>{item.nombre}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    const callService = async(event) => {
        event.preventDefault();
        const dataService = { idInstitucion: id, nombre: nombre, ruc: ruc, telefono: telefono, correo:correo, idTipoInstitucion: idTipoInstitucion };
        await callWebService(rutaServicio, dataService, tipoModal)
        readData();
        clearFields()
    }

    const startInsert = () => {
        clearFields(); setTipoModal(1)
    }

    return (
        <>
            <BasicPage title="Instituciones" icon={<i className="bi bi-folder-fill"></i>} />
            <StartCrud titulo={titulo} table={showTable()} startInsert={startInsert}  />
            {showModal(tipoModal, formContent, titulo, nombre, callService)}
        </>
    );
}

export default Instituciones;