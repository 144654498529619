import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BasicPage } from '../components/BasicPage';
import StartCrud from '../components/StartCrud';
import { ApiWebUrl, botonesTabla, callWebService, showModal } from '../utils';

function TiposActividad(props) {
    const titulo = "tipo de actividad";
    const rutaServicio = ApiWebUrl + "admin/tiposactividad.php";
    const [tipoModal, setTipoModal] = useState(0);
    const [data, setData] = useState([]);
    const [id, setId] = useState("");
    const [nombre, setNombre] = useState("");

    useEffect(() => {
        readData();
        clearFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readData = async (e) => {
        const result = await axios(rutaServicio);
        setData(result.data);
    }

    const fillData = (item) => {
        setId(item.idTipoActividad);
        setNombre(item.nombre);
    }

    const clearFields = () => {
        setId("");
        setNombre("");
    }

    const showTable = () => {
        return (
            <table className='table table-bordered table-striped table-hover crud'>
                <thead className="table-dark">
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item =>
                        <tr key={item.idTipoActividad}>
                            <td>{item.idTipoActividad}</td>
                            <td>{item.nombre}</td>
                            {botonesTabla(item, fillData, setTipoModal)}
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    const formContent = () => {
        return (
            <div className="modal-body">
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Código</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={id}
                            readOnly />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Nombre</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={nombre} required
                            onChange={(event) => setNombre(event.target.value)} />
                    </div>
                </div>
            </div>
        )
    }

    const callService = async (event) => {
        event.preventDefault();
        const dataService = { idTipoActividad: id, nombre: nombre };
        await callWebService(rutaServicio, dataService, tipoModal)
        readData();
        clearFields()
    }

    const startInsert = () => {
        clearFields(); setTipoModal(1)
    }

    return (
        <>
            <BasicPage title="Tipos de actividad" icon={<i className="bi bi-folder-fill"></i>} />
            <StartCrud titulo={titulo} table={showTable()} startInsert={startInsert} />
            {showModal(tipoModal, formContent, titulo, nombre, callService)}
        </>
    );
}

export default TiposActividad;