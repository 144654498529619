import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BasicPage } from '../components/BasicPage';
import StartCrud from '../components/StartCrud';
import { ApiWebUrl, botonesTabla, callWebService, showModal } from '../utils';

function Areas(props) {
    const titulo = "área";
    const rutaServicio = ApiWebUrl + "admin/areas.php";
    const [tipoModal, setTipoModal] = useState(0);    
    const [data, setData] = useState([]);
    const [subData1, setSubData1] = useState([]);
    const [id, setId] = useState("");

    const [nombre, setNombre] = useState("");
    const [correo, setCorreo] = useState("");
    const [telefono, setTelefono] = useState("");
    const [idInstitucion, setIdInstitucion] = useState("");

    useEffect(() => {
        readData();
        readSubData1();
        clearFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);

    const readData = async(e) => {
        const result = await axios(rutaServicio);
        setData(result.data);
    }
    const readSubData1 = async(e) => {
        const rutaServicio = ApiWebUrl + "admin/instituciones.php";
        const result = await axios(rutaServicio);
        setSubData1(result.data);
    }

    const fillData = (item) => {
        setId(item.idArea);
        setNombre(item.nombre);
        setCorreo(item.correo);
        setTelefono(item.telefono);
        setIdInstitucion(item.idInstitucion);
    }
    const clearFields = () => {
        setId("");
        setNombre("");
        setCorreo("");
        setTelefono("");
        setIdInstitucion("");
    }

    const showTable = () => {
        return (
            <table className='table table-bordered table-striped table-hover crud'>
                <thead className="table-dark">
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>Correo electrónico</th>
                        <th>Teléfono</th>
                        <th>Institución</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item =>
                        <tr key={item.idArea}>
                            <td>{item.idArea}</td>
                            <td>{item.nombre}</td>
                            <td>{item.correo}</td>
                            <td>{item.telefono}</td>
                            <td>{item.institucion}</td>
                            {botonesTabla(item, fillData, setTipoModal)}
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    const formContent = () => {
        return (
            <div className="modal-body">
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Código</label>
                    </div>
                    <div className="col-md-8">
                        <input type="number" className="form-control" value={id} readOnly />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Nombre</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={nombre} required
                            onChange={(event) => setNombre(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Correo</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={correo} required
                            onChange={(event) => setCorreo(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Teléfono</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={telefono} required
                            onChange={(event) => setTelefono(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Institución</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idInstitucion} required
                            onChange={(event) => setIdInstitucion(event.target.value)}>
                            <option value="">Seleccione una institución</option>
                            {subData1.map(item =>
                                <option value={item.idInstitucion} key={item.idInstitucion}>{item.nombre}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    const callService = async (event) => {
        event.preventDefault();
        const dataService = { idArea: id, nombre: nombre, correo: correo, telefono: telefono, idInstitucion: idInstitucion };
        await callWebService(rutaServicio, dataService, tipoModal)
        readData();
        clearFields()
    }

    const startInsert = () => {
        clearFields(); setTipoModal(1)
    }

    return (
        <>
            <BasicPage title="Áreas" icon={<i className="bi bi-folder-fill"></i>} />
            <StartCrud titulo={titulo} table={showTable()} startInsert={startInsert} />
            {showModal(tipoModal, formContent, titulo, nombre, callService)}
        </>
    );
}

export default Areas;