import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BasicPage } from '../components/BasicPage';
import StartCrud from '../components/StartCrud';
import { ApiWebUrl, botonesTabla, callWebService, showModal } from '../utils';

function Usuarios(props) {
    const titulo = "usuario";
    const rutaServicio = ApiWebUrl + "admin/usuarios.php";
    const [tipoModal, setTipoModal] = useState(0);
    const [data, setData] = useState([]);
    const [subData1, setSubData1] = useState([]);
    const [subData2, setSubData2] = useState([]);
    const [id, setId] = useState("");

    const [nombres, setNombres] = useState("");
    const [apellidos, setApellidos] = useState("");
    const [correo, setCorreo] = useState("");
    const [cargo, setCargo] = useState("");
    const [idPerfil, setIdPerfil] = useState(0);
    const [idEspecialidad, setIdEspecialidad] = useState(0);
    const [clave, setClave] = useState("");


    useEffect(() => {
        readData();
        readSubData1();
        readSubData2();
        clearFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readData = async (e) => {
        const result = await axios(rutaServicio);
        setData(result.data);
        console.log(result.data)
    }
    const readSubData1 = async (e) => {
        const rutaServicio = ApiWebUrl + "admin/perfiles.php";
        const result = await axios(rutaServicio);
        setSubData1(result.data);
    }
    const readSubData2 = async (e) => {
        const rutaServicio = ApiWebUrl + "admin/especialidades.php";
        const result = await axios(rutaServicio);
        setSubData2(result.data);
    }


    const fillData = (item) => {
        setId(item.idUsuario);
        setNombres(item.nombres);
        setApellidos(item.apellidos);
        setCorreo(item.correo);
        setCargo(item.cargo);
        setIdPerfil(item.idPerfil);
        setIdEspecialidad(item.idEspecialidad);
        setClave(item.clave);
    }
    const clearFields = () => {
        setId("");
        setNombres("");
        setApellidos("");
        setCorreo("");
        setCargo("");
        setIdPerfil(0);
        setIdEspecialidad(0);
    }

    const showTable = () => {
        return (
            <table className='table table-bordered table-striped table-hover crud'>
                <thead className="table-dark">
                    <tr>
                        <th>Código</th>
                        <th>Nombres</th>
                        <th>Apellidos</th>
                        <th>Correo</th>
                        <th>Cargo</th>
                        <th>Perfil</th>
                        <th>Especialidad</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item =>
                        <tr key={item.idUsuario}>
                            <td>{item.idUsuario}</td>
                            <td>{item.nombres}</td>
                            <td>{item.apellidos}</td>
                            <td>{item.correo}</td>
                            <td>{item.cargo}</td>
                            <td>{item.perfil}</td>
                            <td>{item.especialidad}</td>
                            {botonesTabla(item, fillData, setTipoModal)}
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }


    const formContent = () => {
        return (
            <div className="modal-body">
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Código</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={id} readOnly />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Nombres</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={nombres} required
                            onChange={(event) => setNombres(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Apellidos</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={apellidos} required
                            onChange={(event) => setApellidos(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Correo electrónico</label>
                    </div>
                    <div className="col-md-8">
                        <input type="email" className="form-control" value={correo} required
                            onChange={(event) => setCorreo(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Cargo</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={cargo} required
                            onChange={(event) => setCargo(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Perfil</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idPerfil} required
                            onChange={(event) => setIdPerfil(event.target.value)}>
                            <option value="">Seleccione un perfil</option>
                            {subData1.map(item =>
                                <option value={item.idPerfil} key={item.idPerfil}>{item.nombre}</option>
                            )}
                        </select>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Especialidad</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idEspecialidad} required
                            onChange={(event) => setIdEspecialidad(event.target.value)}>
                            <option value="">Seleccione una Especialidad</option>
                            {subData2.map(item =>
                                <option value={item.idEspecialidad} key={item.idEspecialidad}>{item.nombre}</option>
                            )}
                        </select>
                    </div>
                </div>

                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Contraseña</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input id="txtPassword" type="password" className="form-control" value={clave} required
                                            onChange={(e) => setClave(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-8">
                                        <div className='mb-2'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                    onClick={(event) => document.getElementById("txtPassword").setAttribute("type", event.target.checked ? "text" : "password")} />
                                                <label className="form-check-label text-start" htmlFor="flexCheckDefault">
                                                    Mostrar contraseña
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

            </div>
        )
    }

    const callService = async (event) => {
        event.preventDefault();
        const dataService = {
            idUsuario: id,
            nombres: nombres,
            apellidos: apellidos,
            correo: correo,
            cargo: cargo,
            idPerfil: idPerfil,
            idEspecialidad: idEspecialidad,
            clave: clave
        };
        await callWebService(rutaServicio, dataService, tipoModal)
        readData();
        clearFields()
    }

    const startInsert = () => {
        clearFields(); setTipoModal(1)
    }

    return (
        <>
            <BasicPage title="Usuarios" icon={<i className="bi bi-people-fill"></i>} />
            <StartCrud titulo={titulo} table={showTable()} startInsert={startInsert} />
            {showModal(tipoModal, formContent, titulo, nombres, callService)}
        </>
    );
}


export default Usuarios;