import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainHeader from './common/MainHeader';
import MainFooter from './common/MainFooter';

import { LoginPage } from './pages/Login';
import { HomeLayout } from './components/HomeLayout';
import { ProtectedLayout } from './components/ProtectedLayout';
import { ProfilePage } from './pages/Profile';

import Escritorio from './pages/Escritorio';


import Usuarios from './admin/Usuarios';


import { Register } from './pages/Register';
import Areas from './admin/Areas';
import TiposActividad from './admin/TiposActividad';
import Actividades from './admin/Actividades';
import Instituciones from './admin/Instituciones';
import PeriodosIngreso from './admin/PeriodosIngreso';
import ConstanciasAlumnos from './pages/ConstanciasAlumnos';
import { Constancias } from './pages/Constancias';
import { Acta } from './pages/Acta';
import { ProfileStudentPage } from './pages/ProfileStudent';
import { ForgetPassword } from './pages/ForgetPassword';
import Subir from './pages/Subir';
import ReporteExtraCurriculares from './reportes/ReporteExtraCurriculares';
import ReporteInstituciones from './reportes/ReporteInstituciones';
import ReporteConstancias from './reportes/ReporteConstancias';
import Alumnos from './admin/Alumnos';
import { ConstanciasReporte } from './pages/ConstanciasReporte';


function App() {
  return (
    <>
      <div id="wrapper">
        <MainHeader />
        <main id="main-content">
          <Routes>
            <Route element={<HomeLayout />}>
              <Route path="/" element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<Register />} />
              <Route path="/olvidoclave" element={<ForgetPassword />} />
            </Route>

            <Route path="/dashboard" element={<ProtectedLayout />}>
              <Route path="profile" element={<ProfilePage />} />
              <Route path="profilestudent" element={<ProfileStudentPage />} />
              
              <Route path="instituciones" element={<Instituciones />} />
              <Route path="areas" element={<Areas />} />

              {/*<Route path="sedes" element={<Sedes />} />*/}
              <Route path="tipoactividad" element={<TiposActividad />} />
              <Route path="actividad" element={<Actividades />} />
              <Route path="periodosingreso" element={<PeriodosIngreso />} />

              
              <Route path="constanciasalumnos" element={<ConstanciasAlumnos />} />
              <Route path="constancias" element={<Constancias />} />
              <Route path="constancias/:idAlumno" element={<Constancias />} />
              <Route path="constanciasreporte/:idAlumno" element={<ConstanciasReporte />} />
              <Route path="acta/:idAlumno" element={<Acta />} />

              
              
              <Route path="usuarios" element={<Usuarios />} />
              <Route path="alumnos" element={<Alumnos />} />
              <Route path="escritorio" element={<Escritorio />} />
              <Route path="subir" element={<Subir />} />

              <Route path="reportesextracurriculares" element={<ReporteExtraCurriculares />} />
              <Route path="reportesinstituciones" element={<ReporteInstituciones />} />
              <Route path="reportesconstancias" element={<ReporteConstancias />} />

              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </main>
        <MainFooter />
      </div>
    </>
  );
}

export default App;
