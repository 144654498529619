import axios from 'axios';
//export const ApiWebUrl = "http://localhost/saefiis/";
export const ApiWebUrl = "https://fiis.reev01.com/api/";

let itemAlumno = {};

export const setDataAlumno = (item) => {
    itemAlumno = item
    localStorage.setItem("nombreCompleto", JSON.stringify(itemAlumno))
}
export const getDataAlumno = () => {
    return JSON.parse(localStorage.getItem("nombreCompleto"));
}

export const callWebService = async (rutaServicio, dataService, tipoModal
) => {
    switch (tipoModal) {
        case 1:
            await axios.post(rutaServicio, dataService);
            break;
        case 2:
            await axios.put(rutaServicio, dataService);
            break;
        case 3:
            await axios.delete(rutaServicio, { data: dataService });
            break;
        default:
    }
    try{ document.querySelector(".btn-close").click()} catch(err){}
}

export const botonesTabla = (item, fillData, setTipoModal) => {
    return (
        <>
            <td><i className="bi bi-pencil-fill" data-bs-toggle="modal" data-bs-target="#formModal"
                onClick={(event) => { fillData(item); setTipoModal(2) }}></i></td>
            <td><i className="bi bi-x-circle-fill" data-bs-toggle="modal" data-bs-target="#formModal"
                onClick={(event) => { fillData(item); setTipoModal(3) }}></i></td>
        </>
    )
}


export const showModal = (tipoModal, formContent, titulo, nombre, callWebService) => {
    let actionTitle = "";
    let actionButton = "";
    let modalContent = "";
    switch (tipoModal) {
        case 1:
            actionTitle = "Añadir";
            actionButton = "Guardar";
            modalContent = <>{formContent()}</>
            break;
        case 2:
            actionTitle = "Actualizar";
            actionButton = "Actualizar";
            modalContent = <>{formContent()}</>
            break;
        case 3:
            actionTitle = "Eliminar";
            actionButton = "Eliminar";
            modalContent = <div className="modal-body">
                ¿Confirma que desea eliminar <strong>{nombre}</strong>?
            </div>
            break;
        default:
            modalContent = "";
    }
    return (
        <div className="modal fade" id="formModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <form onSubmit={(event) => callWebService(event)}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{actionTitle} {titulo}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {modalContent}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="submit" className="btn btn-primary" id="btnActionButton">{actionButton}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}