import axios from "axios";
import { useEffect, useState } from "react";
import { BasicPage } from "../components/BasicPage";
import { useAuth } from "../hooks/useAuth";
import { ApiWebUrl, callWebService } from "../utils";

export const ProfilePage = () => {
    const { user } = useAuth();
    const rutaServicio = ApiWebUrl + "admin/usuarios.php?idUsuario=" + user.idUsuarioTotal;
    const [nombres, setNombres] = useState("");
    const [apellidos, setApellidos] = useState("");
    const [correo, setCorreo] = useState("");
    const [cargo, setCargo] = useState("");
    const [perfil, setPerfil] = useState("");
    const [especialidad, setEspecialidad] = useState("");
    const [clave, setClave] = useState("");
    const [idEspecialidad, setIdEspecialidad] = useState(0);
    const [idPerfil, setIdPerfil] = useState(0);

    useEffect(() => {
        readData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const readData = async (e) => {
        const result = await axios(rutaServicio);
        setNombres(result.data[0].nombres);
        setApellidos(result.data[0].apellidos);
        setCorreo(result.data[0].correo);
        setCargo(result.data[0].cargo);
        setPerfil(result.data[0].perfil);
        setEspecialidad(result.data[0].especialidad);
        setClave(result.data[0].clave);
        setIdEspecialidad(result.data[0].idEspecialidad);
        setIdPerfil(result.data[0].idPerfil);
    }

    const callService = async (event) => {
        event.preventDefault();
        let respuesta = window.confirm("¿Esta seguro que desea actualizar los datos?");
        if (respuesta === true) {
            const dataService = {
                idUsuario: user.idUsuarioTotal,
                nombres: nombres,
                apellidos: apellidos,
                correo: correo,
                cargo: cargo,
                idPerfil: idPerfil,
                idEspecialidad: idEspecialidad,
                clave: clave
            };
            await callWebService(rutaServicio, dataService, 2)
        }
        readData();
    }

    return (
        <>
            <BasicPage title="Perfil" icon={<i className="bi bi-person-fill"></i>} />
            <section id="registro" className="padded">
                <div className="container">
                    <form onSubmit={(event) => callService(event)}>
                        <div className="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Nombres</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={nombres} required
                                            onChange={(e) => setNombres(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Apellidos</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={apellidos} required
                                            onChange={(e) => setApellidos(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Correo electrónico</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="email" className="form-control" value={correo} required
                                            onChange={(e) => setCorreo(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Cargo</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={cargo} required
                                            onChange={(e) => setCargo(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Especialidad</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="email" className="form-control" value={especialidad} readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Perfil</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input type="text" className="form-control" value={perfil} required readOnly
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                        <label className="form-label">Contraseña</label>
                                    </div>
                                    <div className="col-md-8">
                                        <input id="txtPassword" type="password" className="form-control" value={clave} required
                                            onChange={(e) => setClave(e.target.value)} />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-4">
                                    </div>
                                    <div className="col-md-8">
                                        <div className='mb-2'>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox"
                                                    onClick={(event) => document.getElementById("txtPassword").setAttribute("type", event.target.checked ? "text" : "password")} />
                                                <label className="form-check-label" htmlFor="flexCheckDefault">
                                                    Mostrar contraseña
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    <div className="modal-footer">
                                        <button type="submit" className="btn btn-primary">Actualizar perfil</button>
                                    </div>
                                }
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    );
};
