import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BasicPage } from '../components/BasicPage';
import { useAuth } from '../hooks/useAuth';
import { ApiWebUrl, setDataAlumno } from '../utils';
import './ConstanciasAlumnos.css';
import TableToExcel from "react-table-to-excel";



function ConstanciasAlumnos(props) {

    const [data, setData] = useState([]);
    const [idAlumno, setIdAlumno] = useState("");
    const [nota, setNota] = useState("");
    const [usuario, setUsuario] = useState("");
    const [comentario, setComentario] = useState("");
    const [fechaAcreditacion, setFechaAcreditacion] = useState("");

    const [dataFilter, setDataFilter] = useState([]);
    const [campo, setCampo] = useState("nombres");
    const [campoBuscar, setCampoBuscar] = useState("");
    const [requisitos, setRequisitos] = useState(2);

    const { user } = useAuth();

    var rutaServicio = ""
    if (user.idPerfil !== '2') {
        rutaServicio = ApiWebUrl + "admin/alumnos.php";
    }
    else {
        rutaServicio = ApiWebUrl + "admin/alumnos.php?idEspecialidad=" + user.idEspecialidad;
    }


    useEffect(() => {
        readData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readData = async (e) => {

        const result = await axios(rutaServicio);
        console.log(result)
        setData(result.data);
        setDataFilter(result.data);
        //
        console.log("perfil " + user.idPerfil)
        if (user.idPerfil === "2") {
            setRequisitos(3)
        }
        //setTimeout(primeraBusqueda, 500);
    }

    const primeraBusqueda = () => {

        document.getElementById("btnBuscar").click()
        if (document.getElementById("btnPrimerCheck")) {
            document.getElementById("btnPrimerCheck").setAttribute("checked", "true")
        }

    }

    const showTable = () => {
        return (
            <table className='table table-bordered table-striped table-hover crud' id="tabla-impresion">
                <thead className="table-dark">
                    <tr>
                        <th className='celda-uni'>Código UNI</th>
                        <th>Nombres</th>
                        <th>Apellidos</th>
                        <th>Especialidad</th>
                        <th className='text-end'>Nota</th>
                        <th className='text-center'>Constancias</th>
                        <th className='text-center'>Horas</th>
                        {/*
                        <th className='text-center'>Tipo Act.<br/>Validas</th>
                        <th className='text-center'>Horas<br/>Validas</th>
                        */}

                        {(user.idPerfil === '2') ? "" :
                            <th className='text-center'>Requisitos<br />UECPS</th>
                        }

                        {/*<th className='text-end'>Promedio</th>*/}
                        <th className='celda-icono'><i className="bi bi-files"></i></th>

                        <th className='celda-icono'><i className="bi bi-shield-fill-check"></i></th>
                        {/*<th>Estado</th>*/}
                        <th className='celda-icono'><i className="bi bi-bookmark-star-fill"></i></th>
                        <th className='celda-icono'><i className="bi bi-printer-fill"></i></th>
                    </tr>
                </thead>
                <tbody>
                    {dataFilter.map(item =>
                        <tr key={item.idAlumno}>
                            {console.log(item)}
                            {(user.idPerfil === '2' && user.idEspecialidad === item.idEspecialidad && item.sumaTotalValidad >= 80 && item.cantidadTipoValidad >= 3) ?
                                <>
                                    <td className='celda-uni'>{item.codigoUNI}</td>
                                    <td>{item.nombres}</td>
                                    <td>{item.apellidos}</td>
                                    <td>{item.especialidad}</td>
                                    <td className='text-end'>
                                        {parseInt(item.cantidad) > 0 ? item.nota : ""}
                                    </td>
                                    <td className='text-center'>{item.cantidad}</td>
                                    <td className='text-center'>{item.horas}</td>
                                    {/*
                                    <td className='text-center'>{item.cantidadTipoValidad}</td>
                                    <td className='text-center'>{item.sumaTotalValidad}</td>
                                    <td className='text-center'>{item.sumaTotalValidad>=80 && item.cantidadTipoValidad>=3 ? "Aprueba creditaje":""}</td>
                                    */}
                                    {/*<td className='text-end'>{item.promedio !== null
                                        ? parseFloat(item.promedio).toFixed(2)
                                        : ""
                                    }</td>*/}
                                    <td className='celda-icono'>
                                        {parseInt(item.cantidad) > 0
                                            ? <Link className='botonConstancia' to={"/dashboard/constancias/" + item.idAlumno} title='Constancias'><i className="bi bi-files" onClick={() => setDataAlumno(item)}></i></Link>
                                            : ""}
                                    </td>
                                    <td>
                                        {parseInt(item.cantidad) > 0
                                            ? <i className={item.nota >= 14 ? "bi bi-shield-fill-check otorgado"
                                                : item.comentario.trim().length > 0 ? "bi bi-shield-fill-check observado" : "bi bi-shield-fill-check evaluacion"} data-bs-toggle="modal" data-bs-target="#acreditacionModal" onClick={() => mostrarAcreditacion(item)}></i>
                                            : ""}
                                    </td>
                                    {/*
                                    <td className='text-start'>
                                        {parseInt(item.cantidad) > 0
                                            ? item.nota >= 14 ? "Otorgado" : item.comentario.trim().length>0 ?"Observado":"Evaluación"
                                            : ""}
                                    </td>
                                    */}
                                    <td>
                                        {item.usuario !== null && item.nota >= 14
                                            ? <Link className='botonConstancia' to={"/dashboard/acta/" + item.idAlumno} title='Acta de acreditaje'><i className="bi bi-bookmark-star-fill"></i></Link>
                                            : ""}
                                    </td>
                                    <td className='celda-icono'>
                                        {parseInt(item.cantidad) > 0
                                            ? <Link className='botonConstancia' to={"/dashboard/constanciasreporte/" + item.idAlumno} title='Constancias'><i className="bi bi-printer-fill" onClick={() => setDataAlumno(item)}></i></Link>
                                            : ""}
                                    </td>
                                </>
                                : ""}
                            {user.idPerfil !== '2' ?
                                <>
                                    <td className='celda-uni'>{item.codigoUNI}</td>
                                    <td>{item.nombres}</td>
                                    <td>{item.apellidos}</td>
                                    <td>{item.especialidad}</td>
                                    <td className='text-end'>
                                        {parseInt(item.cantidad) > 0 ? item.nota : ""}
                                    </td>
                                    <td className='text-center'>{item.cantidad}</td>
                                    <td className='text-center'>{item.horas}</td>
                                    {/* 
                                    <td className='text-center'>{item.cantidadTipoValidad}</td>
                                    <td className='text-center'>{item.sumaTotalValidad}</td>
                                    */}
                                    <td className='text-center'>{item.sumaTotalValidad >= 80 && item.cantidadTipoValidad >= 3 ? "Cumple requisitos" : ""}</td>
                                    {/*}
                                    <td className='text-end'>{item.promedio !== null
                                        ? parseFloat(item.promedio).toFixed(2)
                                        : ""
                                    }</td>
                                */}


                                    <td>
                                        {parseInt(item.cantidad) > 0
                                            ? <Link className='botonConstancia' to={"/dashboard/constancias/" + item.idAlumno} title='Constancias'><i className="bi bi-files" onClick={() => setDataAlumno(item)}></i></Link>
                                            : ""}
                                    </td>

                                    <td>
                                        {parseInt(item.cantidad) > 0
                                            ? <i className={item.nota >= 14 ? "bi bi-shield-fill-check otorgado"
                                                : item.comentario.trim().length > 0 ? "bi bi-shield-fill-check observado" : "bi bi-shield-fill-check evaluacion"} data-bs-toggle="modal" data-bs-target="#acreditacionModal" onClick={() => mostrarAcreditacion(item)}></i>
                                            : ""}
                                    </td>
                                    {/* 
                                    <td className='text-start'>
                                        {parseInt(item.cantidad) > 0
                                            ? item.nota >= 14 ? "Otorgado" : item.comentario.trim().length>0 ?"Observado":"Evaluación"
                                            : ""}
                                    </td>
                                    */}
                                    <td>
                                        {item.usuario !== null && item.nota >= 14
                                            ? <Link className='botonConstancia' to={"/dashboard/acta/" + item.idAlumno} title='Acta de acreditaje'><i className="bi bi-bookmark-star-fill"></i></Link>
                                            : ""}
                                    </td>
                                    <td className='celda-icono'>
                                        {parseInt(item.cantidad) > 0
                                            ? <Link className='botonConstancia' to={"/dashboard/constanciasreporte/" + item.idAlumno} title='Constancias'><i className="bi bi-printer-fill" onClick={() => setDataAlumno(item)}></i></Link>
                                            : ""}
                                    </td>
                                </>
                                : ""}
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    const mostrarAcreditacion = (item) => {
        setIdAlumno(item.idAlumno);
        setNota(item.nota);
        //alert(user.idUsuarioTotal);
        //setIdUsuario(parseInt(item.idUsuario) > 0 ? item.idUsuario : user.idUsuarioTotal);
        setUsuario(item.usuario);
        setComentario(item.comentario);
        setFechaAcreditacion(item.fechaAcreditacion);
    }

    const actualizarAcreditacion = async (event) => {
        event.preventDefault();
        const dataService = {
            idAlumno: idAlumno,
            nota: nota,
            idUsuario: user.idUsuarioTotal,
            comentario: comentario
        };
        await axios.put(rutaServicio, dataService)
        alert("Se han actualizado los datos")
        readData();
        try { document.querySelector(".btn-close").click() } catch (err) { }
    }

    const mostrarAcreditacionModal = () => {
        return (
            <div className="modal fade" id="acreditacionModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Acreditación</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form onSubmit={(event) => actualizarAcreditacion(event)}>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label className="form-label text-start">Certificado por</label>
                                            <input type="text" className="form-control" value={usuario !== null ? usuario : ""} required readOnly />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label text-start">Nota</label>
                                            <input type="text" className="form-control" value={nota}
                                                onChange={(event) => setNota(event.target.value)}
                                                readOnly={user.idPerfil !== '2'} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label text-start">Fecha de acreditación</label>
                                            <input type="text" className="form-control" value={fechaAcreditacion} required readOnly />
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="mb-3">
                                            <label className="form-label text-start">Comentario</label>
                                            <textarea type="text" className="form-control"
                                                onChange={(event) => setComentario(event.target.value)}
                                                value={comentario} rows="10" readOnly={user.idPerfil !== '2'} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                {user.idPerfil === '2'
                                    ? <button type="submit" className="btn btn-primary">Actualizar cambios</button>
                                    : null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    const buscar = () => {
        console.log(requisitos)
        let datosFiltrados = data.filter((item) => {
            switch (requisitos) {
                case 1:
                    return campoBuscar !== "" ?
                        (item.sumaTotalValidad >= 80 && item.cantidadTipoValidad >= 3) && (
                            (item["codigoUNI"]).toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
                            (item["nombres"]).toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
                            (item["apellidos"] + "").toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
                            (item["especialidad"] + "").toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1) : (item.sumaTotalValidad >= 80 && item.cantidadTipoValidad >= 3)
                case 2:
                    return campoBuscar !== "" ?
                        (item.sumaTotalValidad < 80 || item.cantidadTipoValidad < 3) && (
                            (item["codigoUNI"]).toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
                            (item["nombres"]).toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
                            (item["apellidos"] + "").toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
                            (item["especialidad"] + "").toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1) : (item.sumaTotalValidad < 80 || item.cantidadTipoValidad < 3)
                default:
                    return campoBuscar !== "" ?
                        (item["codigoUNI"]).toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
                        (item["nombres"]).toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
                        (item["apellidos"] + "").toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 ||
                        (item["especialidad"] + "").toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1
                        : true
            }

        })
        setDataFilter(datosFiltrados);
    }

    function PrintElem(elem) {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>' + document.title + '</title>');
        mywindow.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">'
            + '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>'
            + '<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap" rel="stylesheet">');

        mywindow.document.write('<style>body{font-family:Poppins;padding:100px 20px;}h3{text-align:center;}</style>');
        mywindow.document.write('<style>@media print {.no-print{display:none;}}</style>');
        mywindow.document.write('</head><body>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }

    const exportarExcel = () => {
        let table = document.querySelector('#tabla-impresion');
        TableToExcel.convert(table);
    }
    return (
        <>
            <div id="area-impresion">
                <BasicPage title="Estudiantes en actividades extracurriculares" icon={<i className="bi bi-people-fill"></i>} />

                <div className="container">
                    {/* 
                    <div className='no-print'>
                        <div className="mb-3">
                            Buscar por:
                            <select className="form-select" aria-label="Default select example"
                                required
                                onChange={(event) => {
                                    setCampo(event.target.value);
                                }}>
                                <option value="">Seleccione dato a buscar</option>
                                <option value="nombres" >Nombres</option>
                                <option value="apellidos" >Apellidos</option>
                                <option value="codigoUNI" >Código UNI</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <input type="text" className='form-control' placeholder={'Ingrese ' + campo + ' a buscar'}
                                value={campoBuscar} onChange={(event) => setCampoBuscar(event.target.value)} />
                        </div>
                        <div className="mb-3">
                            <button className='btn btn-primary' id='btnBuscar' onClick={() => buscar()}>Buscar</button>
                        </div>
                    </div>
                    */}

                    <div className='no-print'>
                        <div className="mb-3">
                            <input type="text" className='form-control' placeholder={'Ingrese lo que va a buscar'}
                                value={campoBuscar} onChange={(event) => setCampoBuscar(event.target.value)} />
                        </div>
                        {user.idPerfil !== '2' ? <div className='no-print'>
                            <div className="mb-3" id="ver-requisitos">
                                <input type="radio" name='requisitos' className='btn btn-primary' id='btnPrimerCheck' onClick={() => { setRequisitos(2) }} value="Todos" /> No cumple requisitos
                                <input type="radio" name='requisitos' className='btn btn-primary' onClick={() => { setRequisitos(1) }} value="Todos" /> Cumple requisitos
                                <input type="radio" name='requisitos' className='btn btn-primary' onClick={() => { setRequisitos(3) }} value="Todos" /> Todos
                            </div></div> : ""}
                        <div className="mb-3">
                            <button className='btn btn-primary' id='btnBuscar' onClick={() => buscar()}>Buscar</button>
                        </div>
                    </div>




                    {showTable()}
                </div>



            </div>
            <div className="container">
                <button className="btn btn-primary" onClick={() => PrintElem("area-impresion")}>Imprimir</button>
                <button className="btn btn-success" onClick={() => exportarExcel()}>Exportar a Excel</button>
            </div>
            {mostrarAcreditacionModal()}
            {usuario !== null
                ? ""
                : ""}


        </>
    );
}


export default ConstanciasAlumnos;