import React from 'react';
import FileComponent from '../components/FileComponent';

function Subir() {
    return (
        <div className="App">
            <FileComponent />
        </div>
    );
}

export default Subir;