export const BasicPage = ({ title, subTitle, icon }) => {
    return (
        <header id="page-header">
            <div className="container">
                {icon}
                <h1>{title}</h1>
                <span className="subTitlePage">{subTitle}</span>
            </div>
        </header>
    );
};
