import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AppBar } from "../common/AppBar";

export const HomeLayout = () => {
    const { user } = useAuth();
    const outlet = useOutlet();

    if (user) {
        return <Navigate to="/dashboard/escritorio" replace />;
    }

    return (
        <div>
            <AppBar
                pagesLeft={[
                   // { label: "Inicio", path: "/" }
                ]}
                pagesRight={[
                   // { label: "Iniciar sesión", path: "/login" }
                ]}
            />
            {outlet}
        </div>
    );
};
