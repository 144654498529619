import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BasicPage } from "../components/BasicPage";
import { ApiWebUrl } from "../utils";
import './Register.css';

export const Register = () => {
    const rutaServicio = ApiWebUrl + "registroalumno.php";
    const [email, setEmail] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('fiisCorreo', email)
        await axios.post(rutaServicio,
            formData
        ).then(function (result) {
            console.log(result.data);
            switch (result.data) {
                case 0:
                    alert("El correo ingresado no está registrado como alumno UNI");
                    break;
                case 1:
                    alert("Usted ya estaba registrado");
                    break;
                default:
                    alert("Usted ha sido inscrito. Se ha enviado mensaje a su cuenta de correo con una clave para acceder al sistema");
                    break;
            }
        })
    };
    return (
        <>
            <BasicPage title="Registro" icon={<i className="bi bi-house-fill"></i>} />
            <section id="login" className='padded'>
                <div className="container">
                    <div className='row'>
                        <div className="col-4"></div>
                        <div className="col-4">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='mb-2'>
                                    <p>Ingrese su correo para confirmar su registro. Se le enviará un mensaje al mismo con la constraseña para acceder al sistema</p>
                                </div>
                                <div className='mb-2 input-group'>
                                    <input type="email" className="form-control" placeholder="Correo electrónico"
                                        value={email} required
                                        onChange={(event) => setEmail(event.target.value)} />
                                    {/*<span class="input-group-text">@uni.edu.pe</span>*/}
                                </div>


                                <div className='mb-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Confirme su registro
                                    </button>
                                </div>
                            </form>
                            <div className='mb-1'>
                                <Link to="/login" className="p-2">
                                    Iniciar sesión
                                </Link>
                            </div>


                        </div>
                        <div className="col-4"></div>
                    </div>
                </div>
            </section>
        </>
    );
};
