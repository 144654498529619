import * as React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import './AppBar.css';

export const AppBar = ({ pagesLeft, pagesRight }) => {
    const { user, logout } = useAuth();

    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
                <div className="container">
                    <a className="navbar-brand" href="/">SAE - FIIS</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">


                                {/*
                            pagesLeft?.map((page) => (
                                <li key={page.label} className="nav-item"><Link className="nav-link" to={page.path}>{page.label}</Link></li>
                            ))
                            */}
                                {!!user && user.usuarioTipo === "2" && (
                                    <>
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/dashboard/constancias/">Constancias</Link>
                                        </li>
                                    </>
                                )}
                                {!!user && user.usuarioTipo === "1" && (
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/dashboard/constanciasalumnos">Estudiantes en actividades extracurriculares</Link>
                                    </li>
                                )}
                                {!!user && user.usuarioTipo === "1" && user.idPerfil !== "2" && (
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Administración
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {user.idPerfil === "1" && (
                                                <>
                                                    <li><Link className="dropdown-item" to="/dashboard/usuarios">Usuarios</Link></li>
                                                    <li><Link className="dropdown-item" to="/dashboard/alumnos">Estudiantes</Link></li>
                                                </>
                                            )}
                                            
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><Link className="dropdown-item" to="/dashboard/instituciones">Instituciones</Link></li>
                                            <li><Link className="dropdown-item" to="/dashboard/areas">Áreas</Link></li>
                                            <li><hr className="dropdown-divider" /></li>
                                            <li><Link className="dropdown-item" to="/dashboard/tipoactividad">Tipos de actividad</Link></li>
                                            <li><Link className="dropdown-item" to="/dashboard/actividad">Actividades</Link></li>
                                            <li><Link className="dropdown-item" to="/dashboard/periodosingreso">PeriodosIngreso</Link></li>
                                            
                                        </ul>
                                    </li>

                                    
                                )}

{!!user && user.usuarioTipo === "1" && (
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            Reportes
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><Link className="dropdown-item" to="/dashboard/reportesextracurriculares">Horas por Actividades Extracurriculares</Link></li>
                                            <li><Link className="dropdown-item" to="/dashboard/reportesinstituciones">Constancias por Institución</Link></li>
                                            <li><Link className="dropdown-item" to="/dashboard/reportesconstancias">Búsqueda de Constancias</Link></li>
                                        </ul>
                                    </li>

                                    
                                )}
                            </ul>
                        }
                        <ul className="navbar-nav mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" to="/login"></Link>
                            </li>
                            {pagesRight?.map((page) => (
                                <li key={page.label} className="nav-item"><Link className="nav-link" to={page.path}>{page.label}</Link></li>
                            ))}
                            {!!user && user.usuarioTipo === "1" && (
                                <>
                                </>
                            )}
                            {!!user && (
                                <>
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="bi bi-person-fill"></i>
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            {!!user && user.usuarioTipo === "1" && (
                                                <li><Link className="dropdown-item" to="/dashboard/profile">Perfil</Link></li>
                                            )}
                                            {!!user && user.usuarioTipo === "2" && (
                                                <li><Link className="dropdown-item" to="/dashboard/profileStudent">Perfil</Link></li>
                                            )}
                                            <li
                                                key={"logout"}
                                                onClick={logout}
                                            ><a className="dropdown-item" href="/">
                                                    {"Cerrar sesión"}
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                    <span className="navbar-text" id="navbar-nombrecompleto">
                                        {user.nombres} {user.apellidos}
                                    </span>
                                </>
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
};
