import React from 'react';
import './MainHeader.css';
import logo from '../assets/images/logo-uni.png'
function MainHeader(props) {
    return (
        <header id="main-header">
            <div className="container text-center">
                <div className="row">
                    <div className="col-1">
                        <img src={logo} alt="" className='img-fluid' />
                    </div>
                    <div className="col-10 align-self-center">
                        <p><span className="display-6">Universidad Nacional de Ingeniería</span><br />
                            Facultad de Ingeniería Industrial y Sistemas</p>
                    </div>
                    <div className="col-1"></div>
                </div>
            </div>
        </header>
    );
}

export default MainHeader;