import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BasicPage } from "../components/BasicPage";
import { ApiWebUrl } from "../utils";
import './Register.css';
export const ForgetPassword = () => {
    const rutaServicio = ApiWebUrl + "reestablecerclave.php";
    const [email, setEmail] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('fiisTo', email)
        await axios.post(rutaServicio,
            formData
        ).then(function (result) {
            console.log(result.data);
            if (result.data === 0) {
                alert("El correo ingresado no está registrado");
            }
            else {
                alert("Se ha enviado mensaje a su cuenta de correo con una nueva clave");
            }
        })
    };
    return (
        <>
            <BasicPage title="Reestablecer contraseña" icon={<i className="bi bi-house-fill"></i>} />
            <section id="login" className='padded'>
                <div className="container">
                    <div className='row'>
                        <div className="col-4"></div>
                        <div className="col-4">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='mb-2'>
                                    <p>Ingrese su correo electrónico, para enviarle un mensaje con una nueva constraseña</p>
                                </div>
                                <div className='mb-2'>
                                    <input type="email" className="form-control" placeholder="Correo electrónico"
                                        value={email} required
                                        onChange={(event) => setEmail(event.target.value)} />
                                </div>
                                <div className='mb-5'>
                                    <button type='submit' className='btn btn-primary'>
                                        Enviar
                                    </button>
                                </div>
                            </form>
                            <div className='mb-1'>
                                <Link to="/login" className="p-2">
                                    Iniciar sesión
                                </Link>
                            </div>


                        </div>
                        <div className="col-4"></div>
                    </div>
                </div>
            </section>
        </>
    );
};
