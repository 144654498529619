import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BasicPage } from "../components/BasicPage";
import StartCrud from "../components/StartCrud";
import { useAuth } from "../hooks/useAuth";
import { ApiWebUrl, botonesTabla, callWebService, showModal, getDataAlumno } from "../utils";
import TableToExcel from "react-table-to-excel";

export const Constancias = () => {

    const titulo = "constancia";
    const rutaServicioC = ApiWebUrl + "admin/constancias.php";
    const [tipoModal, setTipoModal] = useState(0);
    const [data, setData] = useState([]);
    const [subData1, setSubData1] = useState([]);
    const [subData2, setSubData2] = useState([]);
    const [subData3, setSubData3] = useState([]);
    const [subData4, setSubData4] = useState([]);
    const [subData5, setSubData5] = useState([]);
    const [id, setId] = useState(0);

    const [idAlumno, setIdAlumno] = useState("");
    const [idArea, setIdArea] = useState("");
    const [idActividad, setIdActividad] = useState("");
    const [horas, setHoras] = useState(0);
    const [nota, setNota] = useState(0);
    const [fechaEmision, setFechaEmision] = useState("");
    const [idDocumentoOrigen, setIdDocumentoOrigen] = useState("");
    const [documentoNombre, setDocumentoNombre] = useState("");
    const [documentoRuta, setDocumentoRuta] = useState(null);
    const [idInstitucion, setIdInstitucion] = useState("");
    const [idTipoActividad, setIdTipoActividad] = useState("");

    const [totalHoras, setTotalHoras] = useState(0);
    /*const [promNotas, setPromNotas] = useState(0);*/


    const [validadoUECPS, setValidadoUECPS] = useState(0);
    const [comentarioUECPS, setComentarioUECPS] = useState("");
    const [validadorUECPS, setValidadorUECPS] = useState("");
    const [fechaValidacionUECPS, setFechaValidacionUECPS] = useState("");

    const [validadoEscuela, setValidadoEscuela] = useState(0);
    const [comentarioEscuela, setComentarioEscuela] = useState("");
    const [validadorEscuela, setValidadorEscuela] = useState("");
    const [fechaValidacionEscuela, setFechaValidacionEscuela] = useState("");

    const [idConstancia, setIdConstancia] = useState("");



    const [file, setFile] = useState([]);

    const [dataFilter, setDataFilter] = useState([]);
    const [campo, setCampo] = useState("nombres");
    const [campoBuscar, setCampoBuscar] = useState("");



    const { user } = useAuth();
    let params = useParams();
    useEffect(() => {

        readData();
        readSubData3();
        readSubData4();//Institucion
        readSubData5();//Tipo Actividad
        clearFields();
        if (user.idPerfil === '2') {

            document.getElementById("btnNuevo").style.display = "none";
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const readData = async () => {
        let idAlumnoParametro = 0;
        if (user.usuarioTipo === "2") {
            idAlumnoParametro = user.idUsuarioTotal
            setIdAlumno(user.idUsuarioTotal);
        }
        if (user.usuarioTipo === "1") {
            idAlumnoParametro = params.idAlumno
            setIdAlumno(params.idAlumno);
        }

        const result = await axios(rutaServicioC + "?idAlumno=" + idAlumnoParametro);
        setData(result.data);
        setDataFilter(result.data);
        console.log(result.data)
        let tHoras = result.data.map(item => item.horas)
        setTotalHoras(tHoras.reduce((acumulador, a) => acumulador + parseInt(a), 0))

        /*let tNotas = result.data.map(item => item.nota);
        setPromNotas(tNotas.reduce((acumulador, a) => acumulador + parseFloat(a), 0) / tNotas.length);*/

    }
    const readSubData1 = (event) => {
        let vidInstitucion = event.target.options[event.target.selectedIndex].value
        leerAreas(vidInstitucion)
    }
    const leerAreas = async (vidInstitucion) => {
        const rutaServicio = ApiWebUrl + "admin/areas.php?idInstitucion=" + vidInstitucion;
        const result = await axios(rutaServicio);
        setSubData1(result.data);
    }


    const readSubData2 = (event) => {
        let vidTipoActividad = event.target.options[event.target.selectedIndex].value
        leerActividades(vidTipoActividad)
    }
    const leerActividades = async (vidTipoActividad) => {
        const rutaServicio = ApiWebUrl + "admin/actividades.php?idTipoActividad=" + vidTipoActividad;
        const result = await axios(rutaServicio);
        setSubData2(result.data);
    }
    const readSubData3 = async (e) => {
        const rutaServicio = ApiWebUrl + "admin/documentoorigenes.php";
        const result = await axios(rutaServicio);
        setSubData3(result.data);
    }
    const readSubData4 = async (e) => {
        const rutaServicio = ApiWebUrl + "admin/instituciones.php";
        const result = await axios(rutaServicio);
        setSubData4(result.data);

    }
    const readSubData5 = async (e) => {
        const rutaServicio = ApiWebUrl + "admin/tiposactividad.php";
        const result = await axios(rutaServicio);
        setSubData5(result.data);
    }


    const fillData = (item) => {

        setId(item.idConstancia);
        setIdArea(item.idArea);
        setIdActividad(item.idActividad);
        setHoras(item.horas);
        setNota(item.nota);
        setFechaEmision(item.fechaEmision);
        setIdDocumentoOrigen(item.idDocumentoOrigen);
        setDocumentoNombre(item.documentoNombre);
        setDocumentoRuta(item.documentoRuta);
        setIdInstitucion(item.idInstitucion);
        setIdTipoActividad(item.idTipoActividad);

        leerAreas(item.idInstitucion);
        leerActividades(item.idTipoActividad);


    }
    const mostrarValidacionUEPS = (item) => {
        setIdConstancia(item.idConstancia);
        setValidadoUECPS(item.validadoUECPS);
        setComentarioUECPS(item.comentarioUECPS);
        setValidadorUECPS(item.validadorUECPS);
        setFechaValidacionUECPS(item.fechaValidacionUECPS);
    }
    const mostrarValidacionEscuela = (item) => {
        setIdConstancia(item.idConstancia);
        setValidadoEscuela(item.validadoEscuela);
        setComentarioEscuela(item.comentarioEscuela);
        setValidadorEscuela(item.validadorEscuela);
        setFechaValidacionEscuela(item.fechaValidacionEscuela);
        console.log(item.comentarioEscuela)
    }


    const clearFields = () => {
        setId("");
        setIdArea("");
        setIdActividad("");
        setHoras(0);
        setNota(0);
        setFechaEmision("");
        setIdDocumentoOrigen("");
        setDocumentoNombre("");
        setDocumentoRuta("");
        setIdInstitucion("");
        setIdTipoActividad("");
    }

    const showTable = () => {
        return (

            <table className='table table-bordered table-striped table-hover crud' id="tabla-impresion">
                <thead className="table-dark">
                    <tr>
                        <th className="no-print">Código</th>
                        <th>Institución</th>
                        <th>Area</th>
                        <th>Tipo actividad</th>
                        <th>Actividad</th>
                        <th>Emisión</th>
                        <th>Horas</th>
                        {/*  <th>Nota</th>*/}
                        <th className="no-print"></th>
                        <th className="no-print"></th>
                        {user.idPerfil !== '2' ?
                            <th className="no-print"></th> : null}
                        <th className="no-print">UECPS</th>
                        <th className="no-print">Escuela</th>
                    </tr>
                </thead>
                <tbody>
                    {console.log(111)}
                    {console.log(dataFilter)}
                    {console.log(222)}
                    {
                        dataFilter.map(item =>
                            <tr key={item.idConstancia}>
                                <td className="no-print">{item.idConstancia}</td>
                                <td>{item.institucion}</td>
                                <td>{item.area}</td>
                                <td>{item.tipoActividad}</td>
                                <td>{item.actividad}</td>
                                <td>{item.fechaEmision}</td>
                                <td className="text-end">{item.horas}</td>
                                {/*<td className="text-end">{parseFloat(item.nota).toFixed(2)}</td>*/}
                                <td className="no-print"><a href={item.documentoRuta} target="_blank" rel="noreferrer" title="Ver constancia"><i className="bi bi-file-earmark-text"></i></a></td>

                                <td className="no-print">

                                    {item.validadoEscuela === 1 || item.validadoUECPS === 1
                                        ? ""
                                        : <i className="bi bi-search" data-bs-toggle="modal" data-bs-target="#formModal"
                                            onClick={(event) => {
                                                fillData(item);
                                                setTipoModal(2);
                                                if (/*(user.usuarioTipo === "1" && (user.idPerfil !== '1' || user.idPerfil !== '3')) ||*/ (user.usuarioTipo === "1" && item.validadoUECPS === 1)) {
                                                    document.getElementById("btnActionButton").style.display = "none";
                                                    document.getElementById("btnActionButton").style.display = "inline";
                                                }
                                            }}></i>}


                                </td>
                                {user.idPerfil !== '2' ?
                                    <td className="no-print">
                                        {item.validadoEscuela === 1 || item.validadoUECPS === 1
                                            ? ""
                                            : <i className="bi bi-x-circle-fill" data-bs-toggle="modal" data-bs-target="#formModal"
                                                onClick={(event) => { fillData(item); setTipoModal(3) }}></i>}

                                    </td>
                                    : null}
                                <td className="no-print"><i className={item.validadoUECPS === 1 ? "bi bi-shield-fill-check validadoUECPS" : "bi bi-shield-fill-check"} data-bs-toggle="modal" data-bs-target="#validacionModalUECPS" onClick={() => mostrarValidacionUEPS(item)}></i></td>
                                <td className="no-print"><i className={item.validadoEscuela === 1 ? "bi bi-shield-fill-check validadoEscuela" : "bi bi-shield-fill-check"} data-bs-toggle="modal" data-bs-target="#validacionModalEscuela" onClick={() => mostrarValidacionEscuela(item)}></i></td>
                            </tr>
                        )}
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan="6" className="text-end">Total de horas</th>
                        <th className="text-end">{totalHoras}</th>
                        <th colSpan="3"></th>
                    </tr>
                    {/*<tr>
                        <th colSpan="6" className="text-end">Nota promedio</th>
                        <th className="text-end">{promNotas !== null ? parseFloat(promNotas).toFixed(2) : ""}</th>
                        <th colSpan="3"></th>
                    </tr>*/}
                </tfoot>
            </table>
        )
    }

    const onChangeImg = (event) => {
        event.preventDefault();
        setFile(event.target.files[0])

    }


    const formContent = () => {
        return (
            <div className="modal-body">
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Código</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={id} readOnly />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Institución</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idInstitucion} required
                            onChange={(event) => {
                                if (event.target.value !== "") {
                                    setIdInstitucion(event.target.value);
                                    readSubData1(event);
                                }
                            }}>
                            <option value="">Seleccione la Institución</option>
                            {subData4 !== null ? subData4.map(item =>
                                <option value={item.idInstitucion} key={item.idInstitucion}>{item.nombre}</option>
                            ) : null}
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Área</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idArea}
                            onChange={(event) => {
                                if (event.target.value !== "") {
                                    setIdArea(event.target.value)
                                }
                            }}>
                            <option value="">Seleccione el Área</option>
                            {subData1 !== null ? subData1.map(item =>
                                <option value={item.idArea} key={item.idArea}>{item.nombre}</option>
                            ) : null}
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Tipos de actividad</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idTipoActividad} required
                            onChange={(event) => {
                                if (event.target.value !== "") {
                                    setIdTipoActividad(event.target.value);
                                    readSubData2(event);
                                }
                            }}>
                            <option value="">Seleccione el tipo de  Actividad</option>
                            {subData5.map(item =>
                                <option value={item.idTipoActividad} key={item.idTipoActividad}>{item.nombre}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Actividad</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idActividad} required
                            onChange={(event) => {
                                if (event.target.value !== "") {
                                    setIdActividad(event.target.value)
                                }
                            }}>
                            <option value="">Seleccione la Actividad</option>
                            {subData2.map(item =>
                                <option value={item.idActividad} key={item.idActividad}>{item.nombre}</option>
                            )}
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Horas</label>
                    </div>
                    <div className="col-md-8">
                        <input type="number" className="form-control" value={horas} required
                            onChange={(event) => setHoras(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Nota</label>
                    </div>
                    <div className="col-md-8">
                        <input type="number" className="form-control" value={nota} required step="0.1"
                            onChange={(event) => setNota(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Fecha emisión</label>
                    </div>
                    <div className="col-md-8">
                        <input type="date" className="form-control" value={fechaEmision} required
                            onChange={(event) => {
                                setFechaEmision(event.target.value);
                                let fechaIngresada = new Date(event.target.value)[Symbol.toPrimitive]('number')
                                if (Date.now() < fechaIngresada) {
                                    alert("No puede ingresar una fecha posterior");
                                    document.getElementById("btnActionButton").classList.add("disabled");
                                }
                                else {
                                    document.getElementById("btnActionButton").classList.remove("disabled");
                                }
                            }} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Origen del documento</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idDocumentoOrigen} required
                            onChange={(event) => setIdDocumentoOrigen(event.target.value)}>
                            <option value="">Seleccione el Origen del documento</option>
                            {subData3.map(item =>
                                <option value={item.idDocumentoOrigen} key={item.idDocumentoOrigen}>{item.nombre}</option>
                            )}
                        </select>
                    </div>
                </div>
                {/*
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Nombre del documento</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={documentoNombre} required
                            onChange={(event) => setDocumentoNombre(event.target.value)} />
                    </div>
                </div>
                */}
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Ruta del documento</label>
                    </div>
                    <div className="col-md-8">
                        <label className="form-label text-start" >{documentoRuta}</label>
                        <input type="file" className="form-control" multiple name="file"
                            onChange={(event) => onChangeImg(event)} />

                    </div>
                </div>
            </div>
        )
    }

    const callService = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('image', file);
        let url = ApiWebUrl + "server.php";
        //let url = "http://localhost/saefiis/server.php";
        await axios.post(url, formData, {
        })
            .then(async (res) => {
                const dataService = {
                    idConstancia: id,
                    idAlumno: idAlumno,
                    idArea: idArea,
                    idActividad: idActividad,
                    horas: horas,
                    nota: nota,
                    fechaEmision: fechaEmision,
                    idDocumentoOrigen: idDocumentoOrigen,
                    documentoNombre: 'A',
                    documentoRuta: res.data.url
                };
                await callWebService(rutaServicioC, dataService, tipoModal)
                readData();
                clearFields()
            })

    }

    const startInsert = () => {
        clearFields(); setTipoModal(1)
    }

    const actualizarValidacionUECPS = async (event) => {
        event.preventDefault();
        const dataService = {
            idConstancia: idConstancia,
            idUsuarioUECPS: user.idUsuarioTotal,
            validadoUECPS: validadoUECPS,
            comentarioUECPS: comentarioUECPS
        };
        await axios.put(rutaServicioC, JSON.stringify(dataService));
        alert("Se han actualizado los datos")
        readData();
        try { document.querySelector("#btn-Close-UECPS").click() } catch (err) { }
    }
    const actualizarValidacionEscuela = async (event) => {
        event.preventDefault();
        const dataService = {

            idConstancia: idConstancia,
            idUsuarioEscuela: user.idUsuarioTotal,
            validadoEscuela: validadoEscuela,
            comentarioEscuela: comentarioEscuela,

        };
        try {
            await axios.put(rutaServicioC, dataService);
            console.log(dataService)
            alert("Se han actualizado los datos")
            readData();
            try { document.querySelector("#btn-Close-Escuela").click() } catch (err) { }
        }
        catch (err) {
            console.log(err)
        }

    }
    const mostrarValidacionModal = () => {
        console.log("----" + comentarioEscuela)
        return (
            <>
                <div className="modal fade" id="validacionModalUECPS" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Validación UECPS</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-Close-UECPS"></button>
                            </div>
                            <form onSubmit={(event) => actualizarValidacionUECPS(event)}>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label className="form-label text-start">Validado por</label>
                                                <input type="text" className="form-control" required readOnly value={validadorUECPS !== null ? validadorUECPS : user.idPerfil === '3' ? user.nombres + " " + user.apellidos : ""} />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label text-start">Fecha de validación</label>
                                                <input type="text" className="form-control" required readOnly value={fechaValidacionUECPS === "0000-00-00 00:00:00" ?
                                                    (new Date()).getFullYear() + "-" + (((new Date()).getMonth() + 1) < 10 ? "0" : "") + ((new Date()).getMonth() + 1) + "-" + ((new Date()).getDate() < 10 ? "0" : "") + (new Date()).getDate() : fechaValidacionUECPS} />
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={validadoUECPS === 1 ? true : false}
                                                        onChange={(event) => { setValidadoUECPS(event.target.checked ? 1 : 0); }} disabled={user.idPerfil !== '3' ? "1" : ""} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">{validadoUECPS === 1 ? "Validado" : "No validado"}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="mb-3">
                                                <label className="form-label text-start">Comentario</label>
                                                <textarea type="text" className="form-control" rows="7"
                                                    onChange={(event) => setComentarioUECPS(event.target.value)} readOnly={user.idPerfil !== '3'} value={comentarioUECPS} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                    {user.idPerfil === '3'
                                        ? <button type="submit" className="btn btn-primary">Actualizar cambios</button>
                                        : null}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="validacionModalEscuela" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Validación Escuela</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-Close-Escuela"></button>
                            </div>
                            <form onSubmit={(event) => actualizarValidacionEscuela(event)}>
                                <div className="modal-body">
                                    <div className='row'>
                                        <div className="col-md-4">


                                            <div className="mb-3">
                                                <label className="form-label text-start">Validado por</label>
                                                <input type="text" className="form-control" required readOnly value={validadorEscuela !== null ? validadorEscuela : user.idPerfil === '2' ? user.nombres + " " + user.apellidos : ""} />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label text-start">Fecha de validación</label>
                                                <input type="text" className="form-control" required readOnly value={fechaValidacionEscuela === "0000-00-00 00:00:00" ?
                                                    (new Date()).getFullYear() + "-" + (((new Date()).getMonth() + 1) < 10 ? "0" : "") + ((new Date()).getMonth() + 1) + "-" + ((new Date()).getDate() < 10 ? "0" : "") + (new Date()).getDate() : fechaValidacionEscuela} />
                                            </div>
                                            <div className="mb-3">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked={validadoEscuela === 1 ? true : false}
                                                        onChange={(event) => setValidadoEscuela(event.target.checked ? 1 : 0)} disabled={user.idPerfil !== '2' ? "1" : ""} />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">{validadoEscuela === 1 ? "Validado" : "No validado"}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="mb-3">
                                                <label className="form-label text-start">Comentario</label>
                                                <textarea type="text" className="form-control" rows="7"
                                                    onChange={(event) => setComentarioEscuela(event.target.value)} readOnly={user.idPerfil !== '2'} value={comentarioEscuela} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

                                    {user.idPerfil === '2'
                                        ? <button type="submit" className="btn btn-primary">Actualizar cambios</button>
                                        : null}


                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const buscar = () => {
        let datosFiltrados = data.filter((item) => {
            return campoBuscar !== "" ? (item[campo]).toLowerCase().indexOf(campoBuscar.toLowerCase()) > -1 : true
        })
        setDataFilter(datosFiltrados);
    }

    function PrintElem(elem) {
        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>' + document.title + '</title>');
        mywindow.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">'
            + '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>'
            + '<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap" rel="stylesheet">');

        mywindow.document.write('<style>body{font-family:Poppins;padding:100px 20px;}h3{text-align:center;}</style>');
        mywindow.document.write('<style>@media print {.no-print{display:none;}}</style>');
        mywindow.document.write('</head><body>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }

    const exportarExcel = () => {
        let table = document.querySelector('#tabla-impresion');
        TableToExcel.convert(table);
    }

    return (
        <><div id="area-impresion" className={"usuario-tipo-" + user.usuarioTipo}>
            <BasicPage
                title={user.usuarioTipo === "1" ? "Constancias de " + getDataAlumno().nombres + " " + getDataAlumno().apellidos : "Constancias"}
                subTitle={user.usuarioTipo === "1" ? getDataAlumno().codigoUNI + " " + getDataAlumno().especialidad : ""}
                icon={<i className="bi bi-people-fill"></i>} />
            <div className="container no-print">
                {/* 
                <div className="mb-3">
                    Buscar por:
                    <select className="form-select" aria-label="Default select example"
                        required
                        onChange={(event) => {
                            setCampo(event.target.value);
                        }}>
                        <option value="">Seleccione dato a buscar</option>
                        <option value="institucion">Institución</option>
                        <option value="area">Area</option>
                        <option value="tipoActividad">Tipo de actividad</option>
                        <option value="actividad">Actividad</option>
                    </select>
                </div>
                <div className="mb-3">
                    <input type="text" className='form-control' placeholder={'Ingrese ' + campo + ' a buscar'}
                        value={campoBuscar} onChange={(event) => setCampoBuscar(event.target.value)} />
                </div>
                <div className="mb-3">
                    <button className='btn btn-primary' id='btnBuscar' onClick={() => buscar()}>Buscar</button>
                </div>
                    */}
            </div>

            <StartCrud titulo={titulo} table={showTable()} startInsert={startInsert} />
        </div>
            {/* 
            <div className="container">
                <button className="btn btn-primary" onClick={() => PrintElem("area-impresion")}>Imprimir</button>
                <button className="btn btn-success" onClick={() => exportarExcel()}>Exportar a Excel</button>
            </div>
            */}
            {showModal(tipoModal, formContent, titulo, "Constancia", callService)}
            {mostrarValidacionModal()}

        </>
    );
}

