import React from 'react'

export default function StartCrud(props) {
    return (
        <section id="crud">
            <div className="container">
                    <div className='mb-3'>
                        <button className="btn btn-primary" id="btnNuevo" type="button"
                            data-bs-toggle="modal" data-bs-target="#formModal"
                            onClick={(event) => {event.preventDefault(); props.startInsert()}} 
                            aria-expanded="false" aria-controls="collapseExample">
                            Añadir {props.titulo}
                        </button>
                    </div>
                    {props.table}
            </div>
        </section>
    )
}
