import axios from "axios";
import moment from "moment";
import 'moment/locale/es';
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { BasicPage } from "../components/BasicPage";
import { ApiWebUrl } from "../utils";


export const Acta = () => {
    const rutaServicio = ApiWebUrl + "admin/alumnos.php";
    const [fecha, setFecha] = useState("");
    const [alumno, setAlumno] = useState({}); 
    const [director, setDirector] = useState({}); 


    let params = useParams();
    useEffect(() => {
        moment.locale('es');
        setFecha(moment().format('D [de] MMMM YYYY'));
        
        leerDatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const leerDatos = async() => {
        const result = await axios(rutaServicio + "?idAlumno=" + params.idAlumno);
        setAlumno(result.data[0]);
        const rutaServicio2 = ApiWebUrl + "admin/usuarios.php";
        const result2 = await axios(rutaServicio2 + "?idEspecialidad=" + result.data[0].idEspecialidad);
        setDirector(result2.data[0])
    }

    function PrintElem(elem) {


        var mywindow = window.open('', 'PRINT', 'height=400,width=600');

        mywindow.document.write('<html><head><title>' + document.title + '</title>');
        mywindow.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">'
            + '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>'
            + '<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap" rel="stylesheet">');

        mywindow.document.write('<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-gH2yIJqKdNHPEq0n4Mqa/HGKIhSkIHeL5AyhkYV8i59U5AR6csBvApHHNl/vI1Bx" crossorigin="anonymous"></link>');
        mywindow.document.write('<style>body{font-family:Poppins;padding:100px;}h3{text-align:center;}</style>');
        mywindow.document.write('</head><body>');
        mywindow.document.write(document.getElementById(elem).innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

        return true;
    }
    return (
        <>
            <BasicPage title="Acta de creditaje" icon={<i className="bi bi-house-fill"></i>} />
            <section className="padded">
                <div className="container">
                    <div className="row">
                        <div className="col-3"></div>
                        <div id="area-impresion" className="col">
                            <h3 className="text-center">ACTA DE OTORGAMIENTO <br /> DE 01 CREDITO POR<br />ACTIVIDADES EXTRACURRICULARES<br/><br/><br/></h3>
                            <p>Lima, <strong>{fecha}</strong></p>
                            <p>Mediante el presente se otorga 01 crédito por actividades extracurriculares al estudiante <strong>{alumno.nombres} {alumno.apellidos}</strong> de la Especialidad <strong>{alumno.especialidad}</strong>, con código <strong>{alumno.codigoUNI}</strong>  por un total de horas de <strong>{alumno.horas}</strong>, con Nota final <strong>{alumno.nota}</strong>, según el cumplimiento de las normas vigentes</p>
                                <br/>
                                Atentamente,
                                <br/><br/><br/><br/><br/><br/><br/>
                                <strong>{director.nombres !== undefined? director.nombres.toUpperCase() :""} {director.apellidos !== undefined? director.apellidos.toUpperCase() :""}</strong><br/>
                                DIRECTOR<br/>
                                ESCUELA PROFESIONAL DE {alumno.especialidad !== undefined? alumno.especialidad.toUpperCase() :""}<br/>
                                FACULTAD DE INGENIERIA INDUSTRIAL Y DE SISTEMAS<br/>
                                UNIVERSIDAD NACIONAL DE INGENIERIA<br/>
                        </div>
                        <div className="col-3"></div>
                        
                    </div>
                    <div className="text-center padded">
                    <button className="btn btn-primary" onClick={() => PrintElem("area-impresion")}>Imprimir acta</button>
                    </div>
                </div>
            </section>
        </>
    );
};
