import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ApiWebUrl } from "../utils";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("user", null);
    const navigate = useNavigate();

    const login = async (data) => {
        const rutaServicio = ApiWebUrl + "login.php";
        var formData = new FormData();
        formData.append("correo", data.email);
        formData.append("clave", data.password);

        fetch(rutaServicio, {
            method: 'POST',
            body: formData
        })
            .then(
                res => res.json()
            )
            .then(
                (result) => {
                    evaluarInicioSesion(result, data)
                }
            )
    };

    const evaluarInicioSesion = (result) => {
        
        switch (result) {
            case -1:
                alert("El correo no está registrado");
                break;
            case -2:
                alert("La contraseña es incorrecta");
                break;
            default:
                alert("Bienvenido " + result.nombres);
                setUser(result);
                if(result.usuarioTipo === "2"){
                    navigate("/dashboard/constancias/idUsuarioTotal", { replace: true });
                }
                else{
                    navigate("/dashboard/constanciasalumnos", { replace: true });
                }
                
        }
    }

    const logout = () => {
        setUser(null);
        navigate("/", { replace: true });
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};