import * as React from "react";
import { Link } from "react-router-dom";
import { BasicPage } from "../components/BasicPage";
import { useAuth } from "../hooks/useAuth";

export const LoginPage = () => {
    const { login } = useAuth();

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);
        login({
            email: data.get("email"),
            password: data.get("password")
        });
    };

    return (
        <>
            <BasicPage title="Iniciar sesión" icon={<i className="bi bi-lock-fill"></i>} />
            <section id="login" className='padded'>
                <div className="container">
                    <div className='row'>
                        <div className="col-4"></div>
                        <div className="col-4">
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='mb-2'>
                                    <input type='text' className='form-control' name="email" required autoComplete="on"
                                        placeholder="Correo electrónico" />
                                </div>
                                <div className='mb-2'>
                                    <input type='password' className='form-control' name="password" required autoComplete="on"
                                        id="txtPassword" placeholder="Contraseña" />
                                </div>
                                <div className='mb-2'>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                            onClick={(event) => document.getElementById("txtPassword").setAttribute("type", event.target.checked ? "text" : "password")} />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Mostrar contraseña
                                        </label>
                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <button type='submit' className='btn btn-primary'>
                                        Iniciar sesión
                                    </button>
                                </div>
                                <div className='mb-5'>
                                    <Link to="/olvidoclave" >
                                        ¿Olvidó su contraseña?
                                    </Link>
                                </div>
                                <div className='mb-1'>
                                    ¿No tiene una cuenta?
                                    <Link to="/register" className="p-2">
                                        Regístrese
                                    </Link>
                                </div>
                            </form>
                        </div>
                        <div className="col-4"></div>
                    </div>
                </div>
            </section>
        </>
    );
};
