import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BasicPage } from '../components/BasicPage';
import StartCrud from '../components/StartCrud';
import { ApiWebUrl, botonesTabla, callWebService, showModal } from '../utils';

function Actividades(props) {
    const titulo = "Actividades";
    const rutaServicio = ApiWebUrl + "admin/actividades.php";
    const [tipoModal, setTipoModal] = useState(0);
    const [data, setData] = useState([]);
    const [subData2, setSubData2] = useState([]);
    const [id, setId] = useState("");

    const [nombre, setNombre] = useState("");
    const [idTipoActividad, setidTipoActividad] = useState(0);


    useEffect(() => {
        readData();
        readSubData2();
        clearFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readData = async (e) => {
        const result = await axios(rutaServicio);
        setData(result.data);
    }



    const readSubData2 = async (e) => {
        const rutaServicio = ApiWebUrl + "admin/tiposactividad.php";
        const result = await axios(rutaServicio);
        setSubData2(result.data);
    }

    const fillData = (item) => {
        setId(item.idActividad);
        setNombre(item.nombre);
        setidTipoActividad(item.idTipoActividad);
    }
    const clearFields = () => {
        setId("");
        setNombre("");
        setidTipoActividad(0);
    }

    const showTable = () => {
        return (
            <table className='table table-bordered table-striped table-hover crud'>
                <thead className="table-dark">
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>Tipo de actividad</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item =>
                        <tr key={item.idActividad}>
                            <td>{item.idActividad}</td>
                            <td>{item.nombre}</td>
                            <td>{item.tipoactividad}</td>
                            {botonesTabla(item, fillData, setTipoModal)}
                        </tr>
                    )}
                </tbody>
            </table>
        )
    }

    const formContent = () => {
        return (
            <div className="modal-body">
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Código</label>
                    </div>
                    <div className="col-md-8">
                        <input type="number" className="form-control" value={id} readOnly />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Nombre</label>
                    </div>
                    <div className="col-md-8">
                        <input type="text" className="form-control" value={nombre} required
                            onChange={(event) => setNombre(event.target.value)} />
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <label className="form-label">Tipo de actividad</label>
                    </div>
                    <div className="col-md-8">
                        <select className="form-select" aria-label="Default select example"
                            value={idTipoActividad} required
                            onChange={(event) => setidTipoActividad(event.target.value)}>
                            <option value="">Seleccione un tipo de actividad</option>
                            {subData2.map(item =>
                                <option value={item.idTipoActividad} key={item.idTipoActividad}>{item.nombre}</option>
                            )}
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    const callService = async (event) => {
        event.preventDefault();
        const dataService = { idActividad: id, nombre: nombre, capacidad: "0", idTipoActividad: idTipoActividad, idSede: "1" };

        await callWebService(rutaServicio, dataService, tipoModal)
        readData();
        clearFields()
    }

    const startInsert = () => {
        clearFields(); setTipoModal(1)
    }

    return (
        <>
            <BasicPage title="Actividades" icon={<i className="bi bi-folder-fill"></i>} />
            <StartCrud titulo={titulo} table={showTable()} startInsert={startInsert} />
            {showModal(tipoModal, formContent, titulo, nombre, callService)}
        </>
    );
}

export default Actividades;